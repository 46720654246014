import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/"
import SEO from "../../../components/seo/"
import Crumbs from "../../../components/breadcrumb/"
import BackNav from "../../../components/back-nav/"

import Split from "../../../components/split/"
import TextBlock from "../../../components/text-block/"
import ImageBlock from "../../../components/image-block/"

import { psColour, wlColour, siteColour } from "../../../data/data.colours"

const WlambPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			farmsBg: file(relativePath: { eq: "backgrounds/church-farm-harvester.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	
	return (
		<>
			<SEO title="Wlamb" />
			<Layout>
				<Crumbs
					pageContext={pageContext}
					location={location}/>
				<BackNav
					hex={psColour.hex}
					link={`/painesend/our-farms`}/>
				<Split>
					<ImageBlock 
						image={ data.farmsBg.childImageSharp.fluid }
						position={`50% 0%`}/>
					<TextBlock 
						title={`Church Farm, Aldbury`}
						titleTag={ `h1` }
						text={`
							<p>This former race yard was acquired in 2012. Work quickly began to convert the former groom's accommodation into seven luxury flats. Following that, the existing residential and commercial properties were refurbished and have been successfully tenanted since. Of the 330-acre farm 250 acres has been reverted into productive arable land, and the addition of a modern 1200-tonne grain store completes the set-up. Planning Permission is being sought to sympathetically convert the old stables into a mixed-use development. Working within the confines of a Conservation Area, W Lamb Ltd will manage both this phase of development and the subsequent property rental management.</p>
							<p>For any enquiries, please email <a href="mailto:admin@painesend.com" target="_blank">admin@painesend.com</a></p>
							<p>Church Farm is also home to a 1500m all-weather Gallops situated in the stunning surroundings of open farmland which is available for exclusive hire to individuals or small groups of riders.</p>
							<p>For more information or to book, please visit <a href="https://en-gb.facebook.com/TringGallops" target="_blank">facebook.com/TringGallops</a></p>
						`}
						bgColour={ psColour.slug }/>

				</Split>
			</Layout>
		</>
	)
}

export default WlambPage
